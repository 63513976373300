export const review_score_values = [
  {
    value: 6,
    label: 'Carino: da 6 in su',
    short_label: 'Carino'
  },
  {
    value: 7,
    label: 'Buono: da 7 in su',
    short_label: 'Buono'
  },
  {
    value: 8,
    label: 'Ottimo: da 8 in su',
    short_label: 'Ottimo'
  },
  {
    value: 9,
    label: 'Eccellente: da 9 in su',
    short_label: 'Eccellente'
  },
]