// site specific mapping
// refs: https://trello.com/c/Fwbzyxcr/2340-hotel-types-categorie

export const hotel_types = {
  201: "Appartamenti",
  202: "Altre strutture ricettive",
  203: "Ostelli",
  204: "Hotel",
  205: "Motel",
  206: "Resort",
  207: "Residence",
  208: "Bed & Breakfast",
  209: "Ryokan",
  210: "Agriturismi",
  212: "Villaggi turistici",
  213: "Ville",
  214: "Campeggi",
  215: "Imbarcazioni",
  216: "Affittacamere",
  217: "Uncertain",
  218: "Locande",
  219: "Residence",
  220: "Case vacanze",
  221: "Lodge",
  222: "Alloggi in famiglia",
  223: "Case di campagna",
  224: "Campeggi di lusso",
  225: "Hotel a capsule",
  226: "Love hotel",
  227: "Riad",
  228: "Chalet",
  229: "Condo",
  230: "Cottage",
  231: "Hotel low cost",
  232: "Gîte francesi",
  233: "Resort cura & benessere",
  234: "Crociere",
  235: "Residenza studentesca"
}

export const hotel_categories = {
  '1_hotel': {
    name: "Hotel & Co.",
    icon: "hotel",
    types: [203, 204, 205, 209, 225, 226],
  },
  '2_apt': {
    name: "Case e appartamenti",
    icon: "door",
    types: [201, 223, 228, 229, 230, 232],
  },
  '3_beb': {
    name: "Bed & breakfast", // originario: "Bed & breakfast e camere", modificato a mockup figma
    icon: "breakfast",
    types: [208, 215, 216, 222, 227],
  },
  '4_agriturismi': {
    name: "Agriturismi",
    icon: "sun",
    types: [210],
  },
  '5_villaggi': {
    name: "Villaggi e resort",
    icon: "beach",
    types: [206, 212],
  },
  '6_campeggi_glamping': {
    name: "Campeggi e glamping",
    icon: "camping",
    types: [214, 224],
  },
}

export function get_hotel_category(key) {
  if (hotel_categories[key] !== undefined) return hotel_categories[key]
  return {
    name: key,
    icon: "symbol_lb",
    types: [],
  }
}