<template>
  <div class="not-found">
    <vb-icon class="not-found__icon" :name="'exclamation'" :color="sass('primary')" :size="40" />
    <div class="not-found__text">
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'loader',

  components: {
  },

  props: {
  },

  data() {
    return {
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
  }

}
</script>

<style lang="scss" scoped>

  .not-found {
    display: flex;
    align-items: flex-start;
    font-size: $font-size-lg;

    &__icon {
      margin-top: 5px;
    }

    &__text {
      line-height: normal;
      margin-left: 10px;
    }
  }

</style>