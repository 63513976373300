var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"result-view"},[_c('b-container',[_c('booking-engine',{on:{"search":e => _vm.search(e)}}),(_vm.searching)?_c('section',{staticClass:"section section--loader"},[_c('loader',{attrs:{"size":"large","show_timing_slot":true}})],1):_c('section',{staticClass:"section filters"},[_c('b-row',[_c('b-col',{staticClass:"filters__sticky-wrapper",class:{ visible: _vm.show_filters },attrs:{"cols":"3"}},[_c('div',{staticClass:"filters__content-wrapper"},[(_vm.filtered.results !== undefined)?_c('div',{staticClass:"d-flex justify-content-between"},[(!_vm.searching && !_vm.appending)?_c('p',[_c('span',{staticClass:"filters__title text-primary"},[_vm._v(" "+_vm._s(_vm.filtered.results.length)+" strutture mostrate "),_c('div',{staticClass:"d-flex justify-content-between"},[(_vm.total_properties)?_c('span',{staticClass:"text--sm"},[_vm._v("su "+_vm._s(_vm.total_properties)+" totali")]):_vm._e()])])]):_vm._e(),_c('p',{staticClass:"mt-1 text--sm text-info underline cursor-pointer toggle-map text-right"},[(_vm.map)?_c('span',{domProps:{"innerHTML":_vm._s('Mostra lista')},on:{"click":function($event){_vm.map = !_vm.map}}}):_vm._e(),(!_vm.map)?_c('span',{domProps:{"innerHTML":_vm._s('Mostra mappa')},on:{"click":function($event){_vm.map = !_vm.map}}}):_vm._e(),(_vm.searching | _vm.appending)?_c('span',[_c('loader')],1):(_vm.offset>-1)?_c('span',{staticClass:"d-block underline cursor-pointer mt-1",domProps:{"innerHTML":_vm._s('Mostra più risultati')},on:{"click":function($event){return _vm.search()}}}):_vm._e()])]):_vm._e(),_c('div',{key:_vm.filtering},[_c('div',[_c('vb-checkbox',{attrs:{"vb_options":{
                    text: 'Mostra in base al credito',
                    switch: true,
                  }},on:{"change":function($event){return _vm.filter_results()}},model:{value:(_vm.filter_availability),callback:function ($$v) {_vm.filter_availability=$$v},expression:"filter_availability"}})],1),(_vm.active_filters().page_filters.length | _vm.active_filters().advanced_filters.length)?_c('div',[_c('hr'),_c('p',{staticClass:"d-flex align-items-center justify-content-between"},[_c('span',{staticClass:"filters__section__title"},[_vm._v("Filtri attivi:")]),_c('span',{staticClass:"text--sm text-info underline cursor-pointer",on:{"click":() => {
                      _vm.reset_all_filters()
                    }}},[_vm._v("cancella filtri")])]),_c('div',{staticClass:"d-flex flex-wrap mt-2"},[_vm._l((_vm.active_filters().page_filters),function(filter,index){return _c('b-badge',{key:_vm._uid+index+'-page-active',staticClass:"d-inline-flex mr-1 cursor-pointer mb-1",attrs:{"pill":"","variant":"primary"},on:{"click":() => {
                        let payload
                        switch (filter.key) {
                          case 'refundable':
                            payload = !_vm.filter_refundable
                            break
                          case 'preferred':
                            payload = !_vm.filter_preferred
                            break
                          case 'stars':
                          case 'amenities':
                            payload = {
                              ...filter.filter,
                              selected: !filter.filter.selected,
                            }
                            break
                        }
                        filter.callback(payload)
                      }}},[_c('div',{domProps:{"innerHTML":_vm._s(filter.text)}}),_c('div',{staticClass:"ml-2"},[_vm._v("✕")])])}),_vm._l((_vm.active_filters().advanced_filters),function(filter,index){return _c('b-badge',{key:_vm._uid+index+'-advanced-active',staticClass:"d-inline-flex mr-1 cursor-pointer mb-1",attrs:{"pill":"","variant":"primary"},on:{"click":() => {
                        filter.callback()
                      }}},[_c('div',{domProps:{"innerHTML":_vm._s(filter.text)}}),_c('div',{staticClass:"ml-2"},[_vm._v("✕")])])})],2)]):_vm._e(),_c('div',{staticClass:"filters__panel mt-3"},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"filters__hotel-categories"},_vm._l((Object.keys(_vm.hotel_categories)),function(category,index){return _c('div',{key:_vm._uid+index+'_type',staticClass:"filters__hotel-categories__item",class:{ active: _vm.advanced_filters.filter_hotel_categories.includes(index+1) },on:{"click":function($event){$event.stopPropagation();return (() => {
                          if(_vm.advanced_filters.filter_hotel_categories.includes(index+1)) {
                            _vm.advanced_filters.filter_hotel_categories = _vm.advanced_filters.filter_hotel_categories.filter(c => { return c!==index+1 })
                          }
                          else _vm.advanced_filters.filter_hotel_categories.push(index+1)
                          _vm.submit_advanced_filters()
                        }).apply(null, arguments)}}},[_c('vb-icon',{staticClass:"filters__hotel-categories__icon",attrs:{"name":_vm.hotel_categories[category].icon,"size":16,"color":'003B95'}}),_c('div',{staticClass:"filters__hotel-categories__name"},[_vm._v(_vm._s(_vm.hotel_categories[category].name))])],1)}),0)])],1)],1),_c('div',{staticClass:"filters__panel mt-3"},[_c('b-row',[_c('b-col',[_c('p',[_c('span',{staticClass:"filters__section__title"},[_vm._v("Valutazione dei viaggiatori")])]),_c('div',{key:_vm.advanced_filters.filter_review_score,staticClass:"filters__review-score"},_vm._l((_vm.review_score_values),function(score,index){return _c('div',{key:_vm._uid+'score'+index,staticClass:"filters__review-score__item"},[_c('vb-checkbox',{attrs:{"vb_options":{
                              type: 'light',
                              text: score.label,
                              value: score.value == _vm.advanced_filters.filter_review_score,
                            }},on:{"change":value => {
                            _vm.update_filter_review_score(score, value)
                          }}})],1)}),0)])],1)],1),_c('div',{staticClass:"filters__panel mt-3"},[(_vm.filtered.refundable)?_c('div',{attrs:{"set-total":_vm.total = _vm.filtered.refundable.length}},[(_vm.total)?_c('vb-checkbox',{attrs:{"vb_options":{
                      text: 'Solo rimborsabili (' + _vm.total + ')',
                      value: _vm.filter_refundable,
                      type: 'light',
                    }},on:{"change":_vm.update_filter_refundable},model:{value:(_vm.filter_refundable),callback:function ($$v) {_vm.filter_refundable=$$v},expression:"filter_refundable"}}):_vm._e()],1):_vm._e(),(_vm.filtered.preferred)?_c('div',{attrs:{"set-total":_vm.total = _vm.filtered.preferred.length}},[(_vm.total)?_c('vb-checkbox',{attrs:{"vb_options":{
                      text: 'Preferiti da Booking.com (' + _vm.total + ')',
                      value: _vm.filter_preferred,
                      type: 'light',
                    }},on:{"change":_vm.update_filter_preferred},model:{value:(_vm.filter_preferred),callback:function ($$v) {_vm.filter_preferred=$$v},expression:"filter_preferred"}}):_vm._e()],1):_vm._e(),(_vm.filter_stars.length)?_c('div',[_c('hr'),_c('p',[_c('span',{staticClass:"filters__section__title"},[_vm._v("Stelle della struttura")])]),_vm._l((_vm.filter_stars),function(option,option_index){return _c('vb-checkbox',{key:_vm._uid+'stars'+option_index,attrs:{"vb_options":{
                      text: option.text + ' ('+option.amount+')',
                      value: option.selected,
                      type: 'light',
                    }},on:{"change":(selected) => {
                      _vm.update_filter_stars({
                        ...option,
                        selected: selected,
                      })
                    }},model:{value:(option.selected),callback:function ($$v) {_vm.$set(option, "selected", $$v)},expression:"option.selected"}})})],2):_vm._e(),(_vm.filter_amenities.length)?_c('div',[_c('hr'),_c('p',[_c('span',{staticClass:"filters__section__title d-flex align-items-center"},[_vm._v(" Servizi "),_c('vb-icon',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('I filtri indicati non rappresentano una valutazione da parte nostra sulla completezza o qualità dei servizi, in particolare per le caratteristiche di accessibilità per disabili, i servizi per bambini ecc.'),expression:"'I filtri indicati non rappresentano una valutazione da parte nostra sulla completezza o qualità dei servizi, in particolare per le caratteristiche di accessibilità per disabili, i servizi per bambini ecc.'",modifiers:{"hover":true,"top":true}}],staticClass:"ml-1 cursor-pointer",attrs:{"name":'info',"color":'ADB5BD',"size":16}})],1),_c('span',{staticClass:"filters__section__subtitle"},[_vm._v(" Alcuni servizi possono prevedere costi aggiuntivi da pagare in loco. ")])]),_vm._l((_vm.filter_amenities),function(option,option_index){return _c('vb-checkbox',{key:_vm._uid+'amenities'+option_index,attrs:{"vb_options":{
                      text: option.text + ' ('+option.amount+')',
                      value: option.selected,
                      type: 'light',
                    }},on:{"change":(selected) => {
                      _vm.update_filter_amenities({
                        ...option,
                        selected: selected,
                      })
                    }},model:{value:(option.selected),callback:function ($$v) {_vm.$set(option, "selected", $$v)},expression:"option.selected"}})})],2):_vm._e()])])])]),(_vm.filtering)?_c('b-col',{attrs:{"cols":"7"}},[_c('loader')],1):_vm._e(),(!_vm.filtering)?_c('b-col',{attrs:{"xs":"12","xl":"9"}},[(!_vm.searching && !_vm.appending)?_c('div',{staticClass:"filters__btn-wrapper"},[_c('div',[_c('h3',{staticClass:"filters__btn-title vb-heading vb-heading--h3",domProps:{"innerHTML":_vm._s(_vm.location.label)}}),_c('div',{staticClass:"filters__btn-resume"},[(_vm.filtered.results)?_c('span',{staticClass:"text-primary"},[_c('strong',[_vm._v(_vm._s(_vm.filtered.results.length)+" strutture mostrate")]),_vm._v(" "),(_vm.total_properties)?_c('span',[_vm._v("su "+_vm._s(_vm.total_properties)+" totali")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"filters__btn-content"},[_c('vb-dropdown',{staticClass:"filters__btn-order mr-2 variant-white",attrs:{"vb_options":{
                  text: _vm.sort_values.filter(s => { return s.key == _vm.sort_by })[0].label,
                  size: 'sm',
                }}},_vm._l((_vm.sort_values.filter(s => { return s.sort })),function(s,index){return _c('b-dropdown-item',{key:_vm._uid+'sort'+index,attrs:{"active":_vm.sort_by == s.key}},[_c('div',{domProps:{"innerHTML":_vm._s(s.label)},on:{"click":() => {
                      _vm.$store.commit('livebk/sort_by', s.key)
                      _vm.filter_results()
                    }}})])}),1)],1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.map),expression:"map"}],staticClass:"results__map"},[_c('results-google-map',{attrs:{"map_markers":_vm.markers,"map_location":_vm.location,"map_info_window":{
                offset: {x: 0, y: -40,}
              },"map_style":{
                styles: [/*snazzy maps json here */],
              }},on:{"item-clicked":i => {
                this.$store.commit('livebk/selected_hotel', i.item)
                this.$refs[i.item.hotel_id][0].navigate({})
              }}}),_vm._l((_vm.filtered.results),function(res){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],key:res.hotel_id},[_c('custom-link',{ref:res.hotel_id,refInFor:true,attrs:{"route_name":'detail',"css_class":'d-none',"as_default":_vm.get_config('guiSettings.openDetailNewTab', false)},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(res.hotel_id)+" ")]},proxy:true}],null,true)})],1)})],2),(!_vm.map)?_c('div',{staticClass:"results__list"},[_c('results-content-block',{attrs:{"hotels":_vm.filtered.results,"searching":_vm.searching,"appending":_vm.appending}}),(_vm.appending)?_c('div',[_c('loader')],1):(_vm.offset>-1)?_c('div',{staticClass:"text-center"},[(!_vm.searching)?_c('vb-button',{attrs:{"vb_options":{
                  text: 'Mostra più risultati',
                }},on:{"click":function($event){return _vm.search()}}}):_vm._e()],1):_vm._e()],1):_vm._e()]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }