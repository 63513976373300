export const amenities = {
  hasParking: {
    name: "Parcheggio",
    icon: "parking",
    visible: true,
  },
  petsAllowed: {
    name: "Animali ammessi",
    icon: "pets",
    visible: true,
  },
  hasRestaurant: {
    name: "Ristorante",
    icon: "restaurant",
    visible: true,
  },
  hasAccessibility: {
    name: "Accessibilità",
    icon: "accessibility",
    visible: true,
  },
  hasChildrenServices: {
    name: "Servizi per bambini",
    icon: "children_services",
    visible: true,
  },
  hasKitchen: {
    name: "Cucina",
    icon: "cutlery",
    visible: true,
  },
  hasPool: {
    name: "Piscina",
    icon: "pool",
    visible: true,
  },
  hasSpa: {
    name: "Spa e Wellness",
    icon: "spa",
    visible: true,
  },
  hasBusinessCenter: {
    name: "Business Center",
    icon: "business_center",
    visible: true,
  },
  hasChargingStation: {
    name: "Stazione di ricarica",
    icon: "business_center",
    visible: true,
  },
}

export function get_amenities(key) {
  if (amenities[key] !== undefined) return amenities[key]

  return {
    name: key,
    icon: "symbol_vv",
  }
}