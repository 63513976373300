export const mealplan = {
  half_board: {
    name: "mezza pensione",
    icon: "restaurant",
    tooltip: '',
  },
  full_board: {
    name: "pensione completa",
    icon: "restaurant",
    tooltip: '',
  },
  all_inclusive: {
    name: "all inclusive",
    icon: "drink",
    tooltip: '',
  },
  breakfast: {
    name: 'colazione',
    icon: "breakfast",
    tooltip: '',
  },
  lunch: {
    name: "pranzo",
    icon: "cutlery",
    tooltip: '',
  },
  dinner: {
    name: "cena",
    icon: "restaurant",
    tooltip: '',
  },
}

export function get_mealplan(key) {
  if (mealplan[key] !== undefined) return mealplan[key]

  return {
    name: key,
    icon: "breakfast",
  }
}