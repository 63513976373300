export const categories = {
  1: 'Hotel & Co',
  2: 'Case e appartamenti',
  3: 'Bed&breakfast', // originario: "Bed & breakfast e camere", modificato a mockup figma
  4: 'Agriturismi',
  5: 'Villaggi e resort',
  6: 'Campeggi e glamping',
}

export function get_categories(key) {
  if (categories[key] !== undefined) return categories[key]
}