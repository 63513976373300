export const cots_extrabeds = {
  extra_bed: {
    name: "Letto supplementare",
    icon: "bed",
    tooltip: '',
  },
  crib: {
    name: "Culla",
    icon: "cot",
    tooltip: '',
  },
  existing_bed: {
    name: "Letto già presente",
    icon: "doublebed",
    tooltip: 'I bambini possono soggiornare in uno dei letti già presenti.',
  }
}

export function get_cots_extrabeds(key) {
  if (cots_extrabeds[key] !== undefined) return cots_extrabeds[key]

  return {
    name: key,
    icon: "symbol_lb",
  }
}