export const charge_price_mode = {
  1: " a soggiorno",
  2: " a persona a soggiorno",
  3: " a notte",
  4: " a persona a notte",
  5: "%",
  10: " importo variabile"
}

export const extra_charge = {
  "City tax": {
    name: "Tasse di soggiorno",
    tooltip: "La tassa di soggiorno viene definita dall'Amministrazione Comunale, deve essere pagata in struttura ed è soggetta a variazioni e l'hotel potrebbe avere inserito dati non corretti. Se desideri informazioni definitive consulta il sito del Comune o contatta la struttura alberghiera."
  },
  "VAT": {
    name: "IVA",
    tooltip: ""
  },
  "Cleaning fee": {
    name: "Pulizia camere",
    tooltip: ""
  },
  "Service charge": {
    name: "Costo del servizio",
    tooltip: ""
  },
  "Environment fee": {
    name: "Tassa ambientale",
    tooltip: ""
  },
}

export function get_extra_charge(key) {
  const charge = extra_charge[key]
  const charge_mode = charge_price_mode[key]

  if (charge !== undefined) {
    return {
      name: charge.name,
      mode: charge_mode !== undefined ? charge_mode : "",
      tooltip: charge.tooltip,
    }
  }

  return {
    name: key,
    mode: charge_mode !== undefined ? charge_mode : "",
    tooltip: ""
  }
}