import { number, largerEq, smaller, sum, multiply, round, floor } from 'mathjs'
import moment from 'moment'
import Vue from 'vue'
import { review_score_values } from '@booking/resources'
const decimals = 2

export function markup(price) {

  let mu = JSON.parse(atob(Vue.prototype.$config.mu)),
    markups = mu['DEFAULT'] || [],
    pricerange_markup = 0,
    customer_markup = number(mu.ADD.p || 0.1),
    mu_percent = customer_markup,
    mu_display_percent = (mu.DISPLAY) ? mu.DISPLAY.p : 0

  markups.forEach(m => {
    if (largerEq(number(price), number(m.min))) {
      pricerange_markup = number(m.p)
    } else {
      return
    }
  })

  if (Vue.prototype.$config.forced_mu) {
    // caso CONFIRM -> markup forzato da confirm()
    mu_percent = JSON.parse(atob(Vue.prototype.$config.forced_mu))
  } else {
    mu_percent = round(sum(pricerange_markup, customer_markup), 3) // percentuale markup 3 decimali
  }


  let markup = round(multiply(price, mu_percent), decimals)
  let live_price = round(sum(price, markup), decimals)

  let display_markup = round(multiply(price, mu_display_percent), decimals)
  let display_price = round(sum(price, display_markup), decimals)

  if (smaller(live_price, price)) live_price = price

  /*window.console.table({
    price: price,
    mu_percent: mu_percent,
    markup: markup,
    live_price: live_price,
    display_price: display_price,
  })*/


  return {
    markup: String(markup), // ammontare del markup
    live_price: String(live_price), // markup su prezzo netto
    display_price: String(display_price), // markup "finto" su prezzo commissionabile
  }

}

export function round_up(price) {
  return round(price, decimals)
}

export function format_date_with_month (time) {
  return moment(time).locale('it').format('DD MMMM YYYY')
}

export function map_markers(items, availability, sass) {
  let markers = []
  if (!items) return []
  items.forEach((item) => {
    let color = '#999999',
      z_index = 1,
      copy = ''

    if (number(item.price) <= availability) {
      color = sass('primary')
      z_index = 10
    }
    else {
      copy = '<div class="copy">\
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><g fill="var(--color)" id="_01_align_center" data-name="01 align center"><path d="M12,24A12,12,0,1,1,24,12,12.013,12.013,0,0,1,12,24ZM12,2A10,10,0,1,0,22,12,10.011,10.011,0,0,0,12,2Z" /><rect x="11" y="5" width="2" height="10" /><rect x="11" y="17" width="2" height="2" /></g></svg>\
          Credito non sufficiente!</div>'
    }

    let stars = '',
      stars_value = number(item.stars)
    if (stars_value > 0) {
      stars += '<div class="stars">'
      for (let i = 0; i < stars_value; i++) {
        stars += '<svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="star fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-star-fill b-icon bi text-secondary"><g><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></g></svg>'
      }
      stars += '</div>'
    }

    let review_score_value = number(item.salabamScore),
      review_score = '',
      review_desc = ''
    if (review_score_value) {
      let review_score_desc = review_score_values.filter(s => { return s.value >= floor(review_score_value) })
      if (review_score_desc.length > 0) review_desc = review_score_desc.reverse()[0].short_label + '<br>'
      review_score += `<div class="score">${review_desc} ${review_score_value}/10</div>`
    }

    markers.push({
      color: color,
      name: item.name,
      content:
        `<div class="info-window">
              <div class="info-window__image">
                <img src="${item.imageUrl}">
              </div>
              <div class="info-window__text">
                <div class="top">
                  <div class="left">
                    <div class="name">${item.name}</div>
                    <div class="address">${item.full_address}</div>
                  </div>
                  <div class="right">
                    <div class="price">${item.price}€</div>
                  </div>
                </div>
                <div class="ratings">${stars}${review_score}</div>
                ${copy}
              </div>
              <div class="info-window__button"><button class="btn btn-primary">Vai alla struttura</button></div>
            </div>`,
      position: {
        lat: number(item.location.latitude),
        lng: number(item.location.longitude)
      },
      icon: {
        url: 'data:image/svg+xml;base64,' + window.btoa('<svg width="30" height="38" viewBox="0 0 30 38" xmlns="http://www.w3.org/2000/svg"><path fill="' + color + '" stroke="white" stroke-width="1" d="M 14.997 0.524 C 11.169 0.524 7.496 2.045 4.787 4.753 C 2.079 7.462 0.558 11.134 0.558 14.963 C 0.558 22.657 13.502 36.296 14.053 36.874 C 14.174 36.998 14.315 37.098 14.474 37.166 C 14.632 37.237 14.802 37.274 14.975 37.277 C 15.148 37.28 15.32 37.249 15.481 37.185 C 15.641 37.122 15.788 37.028 15.913 36.908 C 15.924 36.897 15.935 36.886 15.947 36.874 C 16.497 36.296 29.442 22.657 29.442 14.963 C 29.442 13.067 29.068 11.189 28.342 9.437 C 27.615 7.685 26.552 6.094 25.21 4.752 C 23.869 3.411 22.278 2.348 20.526 1.622 C 18.774 0.897 16.895 0.523 14.997 0.524 Z M 14.997 21.528 C 13.7 21.528 12.431 21.143 11.352 20.42 C 10.273 19.699 9.432 18.674 8.934 17.475 C 8.438 16.275 8.308 14.955 8.562 13.682 C 8.815 12.411 9.441 11.24 10.358 10.322 C 11.276 9.405 12.447 8.779 13.719 8.527 C 14.991 8.273 16.311 8.403 17.511 8.9 C 18.71 9.397 19.735 10.239 20.456 11.318 C 21.179 12.397 21.563 13.666 21.563 14.963 C 21.563 15.825 21.394 16.679 21.063 17.476 C 20.734 18.273 20.249 18.996 19.641 19.606 C 19.031 20.215 18.307 20.699 17.51 21.028 C 16.713 21.359 15.859 21.528 14.997 21.528 Z"/></svg>'),
      },
      item: item,
      z_index: z_index,
    })
  })
  return markers
}