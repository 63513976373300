export const how_to_collect = {
  door_code: 'tramite codice segreto.',
  lock_box: 'in una cassetta di sicurezza.',
  other: 'modalità da definire.',
  reception: 'alla reception.',
  secret_spot: 'in una location segreta.',
  someone_will_meet: 'verranno consegnate da un addetto.',
  unknown: 'modalità da definire.',
}

export const key_location = {
  at_the_property: 'presso la struttura.',
}

export function get_key_collection(key) {

  return how_to_collect.hasOwnProperty(key) ? how_to_collect[key] :
         key_location.hasOwnProperty(key) ? key_location[key] :
         key
}