export const languages = {
  it: {
    label: "Italiano"
  },
  en: {
    label: "Inglese"
  },
  es: {
    label: "Spagnolo"
  },
  fr: {
    label: "Francese"
  },
  de: {
    label: "Tedesco"
  },
  pt: {
    label: "Portoghese"
  },
  ru: {
    label: "Russo"
  },
  zh: {
    label: "Cinese (Mandarino)"
  },
  ja: {
    label: "Giapponese"
  },
  ko: {
    label: "Coreano"
  },
  ar: {
    label: "Arabo"
  },
  hi: {
    label: "Hindi"
  },
  bn: {
    label: "Bengalese"
  },
  ur: {
    label: "Urdu"
  },
  ro: {
    label: "Romeno"
  },
  th: {
    label: "Tailandese"
  },
  tr: {
    label: "Turco"
  },
  nl: {
    label: "Olandese"
  },
  sv: {
    label: "Svedese"
  },
  pl: {
    label: "Polacco"
  },
  cs: {
    label: "Ceco"
  },
  hu: {
    label: "Ungherese"
  },
  el: {
    label: "Greco"
  },
  he: {
    label: "Ebraico"
  },
  fi: {
    label: "Finlandese"
  },
  da: {
    label: "Danese"
  },
  no: {
    label: "Norvegese"
  },
  nn: {
    label: "Nynorsk"
  },
  is: {
    label: "Islandese"
  },
  et: {
    label: "Estone"
  },
  lv: {
    label: "Lettone"
  },
  lt: {
    label: "Lituano"
  },
  sk: {
    label: "Slovacco"
  },
  sl: {
    label: "Sloveno"
  },
  hr: {
    label: "Croato"
  },
  bg: {
    label: "Bulgaro"
  },
  sq: {
    label: "Albanese"
  },
  sr: {
    label: "Serbo"
  },
  mk: {
    label: "Macedone"
  },
  bs: {
    label: "Bosniaco"
  },
  ka: {
    label: "Georgiano"
  },
  hy: {
    label: "Armeno"
  },
  az: {
    label: "Azero"
  },
  uz: {
    label: "Uzbeco"
  },
  kk: {
    label: "Kazako"
  },
  ky: {
    label: "Kirghiso"
  },
  tk: {
    label: "Turkmeno"
  },
  mn: {
    label: "Mongolo"
  },
  tg: {
    label: "Tagico"
  },
  ps: {
    label: "Pashto"
  },
  fa: {
    label: "Persiano"
  },
  ne: {
    label: "Nepalese"
  },
  mr: {
    label: "Marathi"
  },
  gu: {
    label: "Gujarati"
  },
  or: {
    label: "Oriya"
  },
  ta: {
    label: "Tamil"
  },
  te: {
    label: "Telugu"
  },
  kn: {
    label: "Kannada"
  },
  ml: {
    label: "Malayalam"
  },
  si: {
    label: "Singalese"
  },
  sn: {
    label: "Shona"
  },
  nd: {
    label: "Ndebele del Nord"
  },
  dz: {
    label: "Dzongkha"
  },
  lo: {
    label: "Lao"
  },
  km: {
    label: "Khmer"
  },
  my: {
    label: "Birmano"
  },
  vi: {
    label: "Vietnamita"
  },
  ms: {
    label: "Malese"
  },
  tl: {
    label: "Tagalog"
  },
  haw: {
    label: "Hawaiano"
  },
  id: {
    label: "Indonesiano"
  },
  mg: {
    label: "Malgascio"
  },
  sw: {
    label: "Swahili"
  },
  yo: {
    label: "Yoruba"
  },
  ha: {
    label: "Hausa"
  },
  ig: {
    label: "Igbo"
  },
  af: {
    label: "Afrikaans"
  },
  zu: {
    label: "Zulu"
  },
  xh: {
    label: "Xhosa"
  },
  st: {
    label: "Sotho del Sud"
  },
  tn: {
    label: "Tswana"
  }
}

export function get_languages(key) {
  if (languages[key] !== undefined) return languages[key]

  return {
    label: key,
  }
}