import { resource } from './resource'
import { hotel_types, hotel_categories, get_hotel_category } from './hotel_types'
import { amenities, get_amenities } from './amenities'
import { categories, get_categories } from './categories'
import { cots_extrabeds, get_cots_extrabeds } from './cots_extrabeds'
import { languages, get_languages } from './languages'
import { review_score_values } from './review_score_values'
import { extra_charge, get_extra_charge } from './extra_charge'
import { how_to_collect, key_location, get_key_collection } from './key_collection'
import { mealplan, get_mealplan } from './mealplan'

// site resources
export {
  resource,
  hotel_types,
  hotel_categories,
  get_hotel_category,
  amenities,
  categories,
  cots_extrabeds,
  languages,
  review_score_values,
  extra_charge,
  how_to_collect,
  key_location,
  mealplan,
  get_amenities,
  get_categories,
  get_cots_extrabeds,
  get_languages,
  get_extra_charge,
  get_key_collection,
  get_mealplan,
}